/**
 * FullCalendarWrapper.jsx
 *
 * @file This file exports a wrapper for the fullcalendar module. This wrapper is
 * needed, as the module doesn't work with SSR.
 * @author Robin Walter <hello@robinwalter.me>
 */

import Box from '@mui/material/Box'
import NProgress from 'nprogress'
import React from 'react'

// import Fullcalendar
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import deLocale from '@fullcalendar/core/locales/de'
import googleCalendarPlugin from '@fullcalendar/google-calendar'
import momentPlugin from '@fullcalendar/moment'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'

/**
 * This component creates a wrapper around the fullcalendar module to provide SSR.
 *
 * @returns {Node} The rendered FullCalendar.
 */
const FullCalendarWrapper = () => {

	return (
		<Box>
			<FullCalendar
				events={ {
					googleCalendarId: process.env.GATSBY_GOOGLE_CALENDAR_ID
				} }
				googleCalendarApiKey={ process.env.GATSBY_GOOGLE_CALENDAR_API_KEY }
				nowIndicator
				initialView="dayGridMonth"
				locale={ deLocale }
				plugins={ [ dayGridPlugin, googleCalendarPlugin, momentPlugin, momentTimezonePlugin ] }
				timeZone="Europe/Berlin"
				viewDidMount={ () => { NProgress.done() } }
				weekends
				weekNumbers />
		</Box>
	)

}

export default FullCalendarWrapper
